/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import { SiteInformation } from "../utils/site"
import { LocalizationProps } from "../utils/localization"

import "./style.css"

type Props = {
  children?: React.ReactNode
} & LocalizationProps

const Layout: React.FC<Props> = ({ children, lang }) => {
  const { site } = useStaticQuery<SiteInformation>(graphql`
    query {
      site {
        ...SiteInformation
      }
    }
  `)

  return (
    <>
      <Header siteTitle={site.siteMetadata.title} lang={lang} />
      {children}
      <Footer siteTitle={site.siteMetadata.title} lang={lang} />
    </>
  )
}

export default Layout
