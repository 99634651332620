export const LANG_EN = "en"
export const LANG_IT = "it"

 type SUPPORTED_LANG = typeof LANG_EN | typeof LANG_IT

export type LocalizationProps = {
  lang?: SUPPORTED_LANG
}

export function t<DATA>(
  matrix: { [lang in SUPPORTED_LANG]: DATA },
  lang?: SUPPORTED_LANG
): DATA {
  return matrix[lang || LANG_EN]
}
