import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"

import { LocalizationProps, t } from "../utils/localization"

type Props = {
  siteTitle: string
} & LocalizationProps

const Header: React.FC<Props> = ({ siteTitle, lang }) => (
  <nav className="navbar navbar-default navbar-expand-lg fixed-top custom-navbar">
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="icon ion-md-menu"></span>
    </button>
    <StaticImage
      src="../images/app-icon.png"
      placeholder="none"
      className="img-fluid nav-logo-mobile"
      width={44}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Doodle+"
    />
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <div className="container">
        <StaticImage
          src="../images/app-icon.png"
          placeholder="none"
          className="img-fluid nav-logo-desktop"
          width={44}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Doodle+"
        />
        <ul
          className="navbar-nav ml-auto nav-right"
          data-easing="easeInOutExpo"
          data-speed="1250"
          data-offset="65"
        >
          <li className="nav-item nav-custom-link">
            {t(
              {
                en: (
                  <AnchorLink className="nav-link" to="/#hero" title={siteTitle}>
                    {siteTitle}{" "}
                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                  </AnchorLink>
                ),
                it: (
                  <AnchorLink className="nav-link" to="/it/#hero" title={siteTitle}>
                    {siteTitle}{" "}
                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                  </AnchorLink>
                ),
              },
              lang
            )}
          </li>
          <li className="nav-item nav-custom-link">
            {t(
              {
                en: (
                  <AnchorLink
                    className="nav-link"
                    to="/#marketing"
                    title="Features"
                  >
                    Features{" "}
                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                  </AnchorLink>
                ),
                it: (
                  <AnchorLink
                    className="nav-link"
                    to="/it/#marketing"
                    title="Caratteristiche"
                  >
                    Caratteristiche{" "}
                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                  </AnchorLink>
                ),
              },
              lang
            )}
          </li>
          <li className="nav-item nav-custom-link">
            {t(
              {
                en: (
                  <AnchorLink
                    className="nav-link"
                    to="/#download"
                    title="Download"
                  >
                    Download{" "}
                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                  </AnchorLink>
                ),
                it: (
                  <AnchorLink
                    className="nav-link"
                    to="/it/#download"
                    title="Scarica"
                  >
                    Scarica{" "}
                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                  </AnchorLink>
                ),
              },
              lang
            )}
          </li>
        </ul>
      </div>
    </div>
  </nav>
)

export default Header
