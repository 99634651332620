import * as React from "react"
import { Link } from "gatsby"

import { LocalizationProps, t } from "../utils/localization"

type Props = {
  siteTitle: string
} & LocalizationProps

const Footer: React.FC<Props> = ({ siteTitle, lang }) => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <h5>
            {t(
              {
                en: `Download ${siteTitle}`,
                it: `Scarica ${siteTitle}`,
              },
              lang
            )}
          </h5>
          <ul>
            <li>
              <a
                href="https://apps.apple.com/app/apple-store/id1615495712?pt=118008813&ct=Website&mt=8"
                target="_blank"
              >
                App Store
              </a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.caplaz.doodle"
                target="_blank"
              >
                Play Store
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <h5>
            {t(
              {
                en: "Resources",
                it: "Risorse",
              },
              lang
            )}
          </h5>
          <ul>
            <li>
              {t(
                {
                  en: <Link to="/privacy/">Privacy Policy</Link>,
                  it: <Link to="/it/privacy/">Informativa sulla Privacy</Link>,
                },
                lang
              )}
            </li>
            <li>
              {t(
                {
                  en: <Link to="/terms/">Terms &amp; Conditions</Link>,
                  it: <Link to="/it/terms/">Termini e Condizioni</Link>,
                },
                lang
              )}
            </li>
          </ul>
        </div>
        <div className="col-md-3">
          <h5>Company</h5>
          <p>
            {t(
              {
                en: "Caplaz specializes in building cross-platform compatible Mobile apps. Using the latest technology stacks, we develop scalable Mobile apps so you can take your MVP to market and then add more features later.",
                it: "Caplaz è specializzata nella creazione di app mobili compatibili multipiattaforma. Utilizzando gli stack tecnologici più recenti, sviluppiamo app mobili scalabili in modo che tu possa portare il tuo MVP sul mercato e quindi aggiungere altre funzionalità in un secondo momento.",
              },
              lang
            )}
          </p>
          <p>
            <a href="mailto:info@caplaz.com" className="external-links">
              info@caplaz.com
            </a>
          </p>
        </div>
      </div>
      <div className="divider"></div>
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <a href="#">
            <i className="icon ion-logo-facebook"></i>
          </a>
          <a href="#">
            <i className="icon ion-logo-instagram"></i>
          </a>
          <a href="#">
            <i className="icon ion-logo-twitter"></i>
          </a>
          <a href="#">
            <i className="icon ion-logo-youtube"></i>
          </a>
        </div>
        <div className="col-md-6 col-xs-12">
          <small>
            {t(
              {
                en: "2022 © All rights reserved. Made by",
                it: "2022 © Tutti i diritti riservati. Realizzato da",
              },
              lang
            )}{" "}
            <a
              href="https://www.caplaz.com/"
              target="blank"
              className="external-links"
            >
              Caplaz
            </a>
          </small>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
